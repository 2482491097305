<template>
  <el-container id="login">
    <!-- 顶部 -->
    <el-header style="height: 114px;">
      <!-- 左边logo -->
      
      <div class="header-left">
        
        
        
      </div>
      <div class="title"><img
          class="img"
          src="../assets/img/logo.png"
          alt=""
        ></div> 
    </el-header>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="rulesLoginForm"
      
      
    >

     <el-radio-group v-model="loginForm.mode">
       <el-tooltip content="监管用户登录，请点我！" placement="top">
      <el-radio-button label="监管部门"></el-radio-button>
       </el-tooltip>
       <el-tooltip content="企业用户登录，请点我！" placement="top">
      <el-radio-button label="企业用户"></el-radio-button>
       </el-tooltip>
     
    </el-radio-group>
    <!-- <el-radio v-model="loginForm.mode" label="1">监管部门</el-radio>
    <el-radio v-model="loginForm.mode" label="2">企业用户</el-radio> -->
      <el-form-item
      
        prop="user_login"
      >
        <el-input
        placeholder="请输入手机号或邮箱"
          prefix-icon="el-icon-user-solid"
          v-model="loginForm.user_login"
          clearable
        />
      </el-form-item>
      <el-form-item
        
        prop="pwd"
      >
        <el-input
        placeholder="请输入登录密码"
          prefix-icon="el-icon-c-scale-to-original"
          v-model="loginForm.pwd"
          show-password
          clearable
          @keyup.enter.native="submitForm"
        />
      </el-form-item>
      <el-row type="flex" class="row-bg" justify="center">
  <el-col :span="10"><el-button class="butt register" @click="toregister">还没账号？点击注册</el-button></el-col>
  <el-col :span="10">
    <el-button
      size="medium"
        ref="loginButton"
        type="success"
        @click="submitForm"
         class="butt"
       
      >
        登录
      </el-button>
  </el-col>
  
</el-row>
      
      
    </el-form>
  </el-container>
</template>

<script>
	
export default {
  name: 'Login',
  data () {
    return {
      mode:'',
      loginForm: {
        user_login: '',
        pwd: '',
        mode:'企业用户',
      },
      // 长度校验
      rulesLoginForm: {
        user_login: [{
          required: true,
          message: '请输入登录名',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 30,
          message: '长度在 6 到 21 个字符',
          trigger: 'blur'
        }
        ],
        pwd: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 21,
          message: '长度在 6 到 21 个字符',
          trigger: 'blur'
        }
        ]
      }
    }
  },
  // 方法绑定
  methods: {
    addByEnterKey(e) {
	        //Enter键的代码就是13
            if (e.keyCode == 13) {
              this.submitForm();
            }
        },
    toregister(){
      this.$router.push('/register')
    },
    // 检测是否初始化系统
    // async isSysteInit () {
    //   const formData = JSON.stringify({ useraction: 'isSystemInit' })
    //   const result = await this.$http.post('/user/', formData)
    //   // 判断业务逻辑
    //   if (result.data.ret === 1) {
    //     this.$router.push('init')
    //   }
    // },
    // 表单提交方法
   async submitForm () {
   

      // 设置按钮状态
      const message = this.$message
      const route = this.$router
      this.$refs.loginForm.validate(async valid => {
        var loginButton = this.$refs.loginButton
        // 判断是否合法
        if (valid) {
          loginButton.loading = true
          // const formData = JSON.stringify({
          //   ...this.loginForm
          // })
          // 提交表单
          let login= ''
          if(this.loginForm.mode=="企业用户"){
              login='dologin'
          }else if(this.loginForm.mode=="监管部门"){
              login='login'
          }
          try {
            
          
          const result = await this.$http.post(login, this.loginForm)
          console.log(result.data.code)
          if (result.data.code==0) {
            loginButton.loading = false
            // 登录成功保存用户账号
            if(login=='login'){
                sessionStorage.setItem("token",result.data.data.token)
                sessionStorage.setItem('auth',result.data.data.auth)
              sessionStorage.setItem("info",result.data.data.qyinfo)
              
              route.push('/admin/bigdata')
            }else if(login=='dologin'){
              sessionStorage.setItem("token",result.data.data.token)
              sessionStorage.setItem('auth',result.data.data.auth)
              sessionStorage.setItem("qyinfo",JSON.stringify(result.data.data.qyinfo))
             
              route.push('/company/bigdata').catch(err=>{console.log(err)})
            }
            
          } else {
            loginButton.loading = false
            message({
              message: result.data.message,
              type: 'error',
              showClose: true,
              center: true
            })
          }
          } catch (error) {
            loginButton.loading = false
            message({
              message: error,
              type: 'error',
              showClose: true,
              center: true
            })
          }
        }
      })
    },

    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer) // 在Vue实例销毁前，清除定时器
      }
    }
  },

  created () {
    this.timer = setInterval(this.getTime, 1000)
  }
}
</script>

<style lang="less" scoped>
.el-row {
    margin-bottom: 20px;
    margin: 0 auto;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
    .el-header {
        height: 114px;
        color: black;
        font-size: 1;
        line-height: 10px;
    }

    .header-left {
        float: left;
    }

    .img {
        height: 114px;
    }

    .header-right {
        float: right;
    }

    .header_time {
        text-align: center;
        line-height: 44px;
        height: 44px;
        background-color: #468b37;
        color: white;
        font-size: 16px;
        width: 290px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    .title{
      font-family: "PingFang SC";
      margin-top: 80px;
      color: white;
      font-size: 60px;
      text-align: center;
    }
    .el-form {
        padding: 40px 20px 20px 0px;
        // background-color: rgba(234, 234, 234, 0.7);
        margin: 0 auto;

        width: 450px;
        height: 280px;
        margin-top: 150px;
        color: #333;
        text-align: center;
        // line-height: 180px;
        // border-radius: 10px;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

        >div {
            margin: 28px 0;
        }
        .butt{
          width: 80px;
          height: 40px;
          // font-size: 22px;
          background: #43cc99;
    color: #fff;
    transition: all .5s;
    cursor: pointer;
    width: 180px;
    height: 56px;
    border: none;
    border-radius: 50px;
    margin-right: 50px;
        }
      .butt:hover{
        background: -webkit-linear-gradient(left,#5bd4fd,#2f98fb);
      }
    }
    .register{
       background: #43cc99;
       
    }

    #login {
        height: 100%;
        background: url() bottom center no-repeat;
        background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
        background-image: url("../assets/img/zz.png"),linear-gradient(0deg,#30cfd0 0,#330867);
        // background-image: ;
        background-size: 100% 100%;
        background-size: cover;
    }
</style>
